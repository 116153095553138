.select {
  /* position: absolute;
  z-index: 199;
  top: 24%;
  width: 27vw;
  right: 4%; */
  width: 33%;
}

.select:nth-of-type(2) {
  /*  margin-top: 5vw; */
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.select > button {
  background: white;
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: left;
  color: white;
  position: relative;
  /*   height: 4vw; */
  font-size: 1.2rem;
  border-radius: 10%;
  background-color: #201c38;
  text-align: center;
}

/* .select > button.opened::after {
  width: 2.1vw;
  height: 2.1vw;
  content: "+";
  font-size: 2vw;
  text-align: center;
  line-height: 1.7vw;xp
  color: #575756;
  border: 1px solid #575756;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  top: 25%;
  right: 7%;
} */

/* button:focus {
  outline: none;
} */

/* .select > button span {
  position: relative;
} */

.select > button::after {
  content: "";
  top: 40%;
  border-color: #59d4ff transparent transparent;
  border-width: 0.5rem 0.5rem 0;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  left: 80%;
}

.select_list {
  width: 100%;
  background: white;
  font-family: "Source Sans Pro", sans-serif;
  padding: 2vw; /* desktop */
  margin-top: 1vw;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.4);
}

.select_list::before {
  border-style: solid;

  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 45%;
  top: 4vw;
  width: 0;
  border-color: transparent transparent white;
  border-width: 0 1.325vw 1.325vw;
}

.filter {
  font-size: 1.5vw;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 0.8vw;
  color: #747576;
}

.select_list a {
  /* padding: 10px 15px; */
  padding: 0.8vw 0;
  display: flex;
  color: #723eff;
  text-decoration: none;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #59d4ff; /* desktop*/
  font-size: 1.2rem;
  /* border: 1px solid #c0c0c0; */
  /* margin-bottom: 2vw; */
}
.select_list a:last-of-type {
  /* padding: 10px 15px; */
  padding: 0.8vw 0;
  display: flex;
  color: #723eff;
  text-decoration: none;
  position: relative;
  align-items: center;
  border-bottom: 0px; /* desktop*/
}

.buttonList {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5vw;
}

.buttonList button {
  padding: 0.6vw 1.5vw;
  font-size: 1.5vw;
  color: #747576;
  cursor: pointer;
}

.select_list a:after {
  width: 1rem;
  height: 1rem;
  content: " ";
  font-size: 2vw;
  text-align: center;
  line-height: 1.7vw;
  color: #747576;
  border: 1px solid #747576;
  border-radius: 50%;
  display: block;
  margin-left: auto;
}

.select_list a.selected:after {
  content: " ";
  color: white;
  font-size: 1.3vw;
  text-align: center;
  line-height: 1vw;
  margin-left: auto;
  line-height: 2.1vw;
  border: 0;
  background-color: #201c38;
}

.hide {
  display: none;
}

/* @media (max-width: 700px) {
  .select {
    position: relative;
    width: auto;
    z-index: 99;
    top: 0%;
    right: 0%;
  }

  .select > button {
    color: #fdbe42;
    border: 0;
    margin-bottom: 3vw;
    font-size: 4.5vw;
    font-family: "Chivo";
    background: 0;
    font-size: 7.5vw;
    padding: 0;
    margin-bottom: 6vw;
    margin-top: 9vw;
  }

  .select > button:after {
    display: none;
  }

  .select > button.opened:after {
    display: none;
  }

  .select_list {
    padding: 0;
    box-shadow: none;
  }

  .select_list a {
    padding: 0.8vw 0;
    display: flex;
    color: #929292;
    text-decoration: none;
    position: relative;
    align-items: center;

    font-size: 1.5vw;
    border: 1px solid #c0c0c0;
    margin-bottom: 2vw;
    padding: 2.5vw;
    font-size: 4.5vw;
  }

  .select_list a:after {
    width: 5.5vw;
    height: 5.5vw;
    content: "+";
    font-size: 5.5vw;
    text-align: center;
    line-height: 4.5vw;
    color: #929292;
    border: 1px solid #c0c0c0;
    border-radius: 50%;
    display: block;
    margin-left: auto;
  }

  .select_list a.selected:after {
    content: "\2713";
    color: white;
    font-size: 4.5vw;
    text-align: center;
    line-height: 1vw;
    margin-left: auto;
    line-height: 5.5vw;
  }
} */
