.container {
    background-color: white;
    width: 100%;
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3rem;
}

.container:hover {
    border-color: hsl(0,0%,70%);;
    border-width: 2px;
    border-style: solid;
}

.input {
    width: 95%;
    height: 50%;
    border: 0;
    margin-left: 2.5rem;
    font-size: 1.2rem;
}

.input::placeholder {
    color: black;
}
