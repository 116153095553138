.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.button {
    width: 100px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 10px;
    margin: 7px 10px 7px 10px;
}

.button:hover {
    opacity: 0.7;
}

.button-facebook {
    background: #3B5998;
    color: white;
}

.button-twitter {
    background: #55ACEE;
    color: white;
}

.icon {
    font-size: 14px;
    margin-right: 0.5vw;
}

.shareText {
    font-size: 12px;
}

/*remove profile pic margin*/
.lapFIS {
    margin-right: 0rem !important;
}
